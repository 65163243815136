import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import "./Footer.css";
import logo from "../images/Logo_without_background.png";

export default function Footer() {
  return (
    <div class="footer">
      <div class="row social_media">
        <div class="col-9">GET CONNECTED WITH US ON SOCIAL NETWORK:</div>
        <div class="col-3">
          <FaFacebook /> &emsp; <FaLinkedin /> &emsp; <FaTwitter /> &emsp;
          <FaInstagram /> &emsp;
          <FaYoutube />
        </div>
      </div>

      <div class="row addresses">
        <div class="col-lg-3">
          <img src={logo} alt="SA Trademark Logo" width="280" height="150" />
          <br />
          <div class="menu-myfootermenu-container extra_menus">
            <b>Extra Menus</b>
            <ul id="menu-myfootermenu" class="menu">
              <li
                id="menu-item-719"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-719"
              >
                <a class="" href="ExploreUs">Explore us</a>
              </li>
              <li
                id="menu-item-1304"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1304"
              >
                <a href="">Our Offices</a>
              </li>
              <li
                id="menu-item-716"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-716"
              >
                <a href="">Blogs</a>
              </li>
              <li
                id="menu-item-721"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-721"
              >
                <a href="products" >Products</a>
              </li>
              <li
                id="menu-item-717"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-717"
              >
                <a href="">Gallery</a>
              </li>
              <li
                id="menu-item-718"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-718"
              >
                <a href="">Join us</a>
              </li>
              <li
                id="menu-item-720"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-720"
              >
                <a href="ContactUs">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 footer2ndCol">
          <b>BANGLADESH OFFICE</b> <br />
          <b>Corporate office</b> - House 47, Road-16, Sector-12, Uttara, Dhaka{" "}
          <br />
          Email: satrademarkservicecare@gmail.com
          <br />
          Phone: +88 0964-2525354
          <br />
        </div>
      </div>
    </div>
  );
}
