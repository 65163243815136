import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import kidPic from "../../../images/kids dress/Kids.png";
import menPic from "../../../images/men's fashion/mainPic.png";
import femalePic from "../../../images/Female's fashion/mainPic.png";
import demoPic2 from "../../../images/kids dress/BABY-KIDS-SHIRT.png";
import "./HomeProducts.css";

export default function HomeProducts() {
  const options = {
    items: 1,
    loop: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    navText: [
      "<FontAwesomeIcon icon={faChevronLeft} />",
      "<FontAwesomeIcon icon={faChevronRight} />", 
    ],
  };

  return (
    <div>
      <section className="products-part" id="product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-11 m-auto col-md-12 col-sm-12 col-12">
              <div className="products-part-main">
                <h1
                  className="common-title aos-init"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  Products
                </h1>
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  <div className="item">
                    <div className="row">
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 col-xl-3 col-xxl-3">
                        <div className="slider-for-product">
                          <div className="product-left-slider-content">
                            <div
                              className="product-left-slider-content-image aos-init"
                              data-aos="fade-up"
                              data-aos-duration="700"
                            >
                              <a href="">
                                <img
                                  decoding="async"
                                  src={kidPic}
                                  alt="Category Image"
                                  className="img-fluid"
                                />
                              </a>
                              <h5
                                data-aos="fade-up"
                                data-aos-duration="500"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Playfair Display",
                                  paddingTop: "3px",
                                }}
                                className="aos-init"
                              >
                                {" "}
                                <b>KID'S FASHION</b>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-9 col-md-12 col-sm-12 col-8 col-xl-9 col-xxl-9 product_item_column aos-init"
                        data-aos="fade-up"
                        data-aos-duration="700"
                      >
                        <div className="row" style={{ display: "flex" }}>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S BOTTOM & DENIM</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S JERSEY</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S KNITWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S NIGHTWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S OUTERWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SHIRT</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SWIM SHORTS</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">WOVEN DRESS</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="row">
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 col-xl-3 col-xxl-3">
                        <div className="slider-for-product">
                          <div className="product-left-slider-content">
                            <div
                              className="product-left-slider-content-image aos-init"
                              data-aos="fade-up"
                              data-aos-duration="700"
                            >
                              <a href="">
                                <img
                                  decoding="async"
                                  src={menPic}
                                  alt="Category Image"
                                  className="img-fluid"
                                />
                              </a>
                              <h5
                                data-aos="fade-up"
                                data-aos-duration="500"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Playfair Display",
                                  paddingTop: "3px",
                                }}
                                className="aos-init"
                              >
                                {" "}
                                <b>MEN'S FASHION</b>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-9 col-md-12 col-sm-12 col-8 col-xl-9 col-xxl-9 product_item_column aos-init"
                        data-aos="fade-up"
                        data-aos-duration="700"
                      >
                        <div className="row" style={{ display: "flex" }}>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S BOTTOM & DENIM</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S JERSEY</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S KNITWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S NIGHTWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S OUTERWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SHIRT</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SWIM SHORTS</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">WOVEN DRESS</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item" >
                    <div className="row">
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 col-xl-3 col-xxl-3">
                        <div className="slider-for-product">
                          <div className="product-left-slider-content">
                            <div
                              className="product-left-slider-content-image aos-init"
                              data-aos="fade-up"
                              data-aos-duration="700"
                            >
                              <a href="">
                                <img
                                  decoding="async"
                                  src={femalePic}
                                  alt="Category Image"
                                  className="img-fluid"
                                />
                              </a>
                              <h5
                                data-aos="fade-up"
                                data-aos-duration="500"
                                style={{
                                  textAlign: "center",
                                  fontFamily: "Playfair Display",
                                  paddingTop: "3px",
                                }}
                                className="aos-init"
                              >
                                {" "}
                                <b>FEMALE'S FASHION</b>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-9 col-md-12 col-sm-12 col-8 col-xl-9 col-xxl-9 product_item_column aos-init"
                        data-aos="fade-up"
                        data-aos-duration="700"
                      >
                        <div className="row" style={{ display: "flex" }}>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S BOTTOM & DENIM</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S JERSEY</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S KNITWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S NIGHTWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S OUTERWEAR</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SHIRT</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">KID'S SWIM SHORTS</a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 p_l_r_1 col-md-3 col-sm-6 col-6"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="product-right-slider-content-image">
                              <a href="">
                                <img
                                  decoding="async"
                                  src={demoPic2}
                                  alt="Category Image"
                                  style={{ height: "240px" }}
                                  className="img-fluid"
                                />
                              </a>
                              <div className="product-right-title">
                                <a href="">WOVEN DRESS</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>

                
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
