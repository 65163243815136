import React from 'react';
import './App.css';

import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import Explore_us from './pages/Explore_us';
import Contact from './pages/contactUs/Contacts';
import Blogs from './pages/Blogs';
import Products from './pages/Products';
import Product from './pages/Product';
import Banner from './pages/exploreUs/banner/Banner';
import Content from './pages/exploreUs/content/Content';



function App() {
  return (
    <>
      <Router>
        <Header/>
        
        <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/exploreUs" element={<Explore_us />}></Route>
        <Route path="/contactUs" element={<Contact />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/product" element={<Product />}></Route>
        <Route path="/banner" element={<Banner />}></Route>
        <Route path="/content" element={<Content />}></Route>
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;