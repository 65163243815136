import React from "react";
import "./Content.css";
import "animate.css";
import lineImage from "../../../images/sideline.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  // Global settings:
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  once: false, // whether animation should happen only once - while scrolling down
  mirror: true, // whether elements should animate out while scrolling past them
});

export default function ExploreUsContent() {
  return (
    <div class="exploreUsContent">
      <div class="row exploreUsContent">
        <div class="col-lg-7">
          <br />
          <h2
            data-aos="fade-up"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="2500"
          >
           SA Trademark is a Multinational apparel buying hub and
            exporter with a vision to deliver world-class service.
          </h2>
          <br />
          <p>
            SA Trademark is a global hub for multinational apparel sourcing and
            exporting, our vision is to provide exceptional services,
            high-quality solutions, and value-added services to the textile
            industry worldwide. Our expertise spans from fabric sourcing to
            delivering fashion-forward products, backed by our extensive market
            knowledge and insights.SA Trademark
            offers product sourcing, amazing design collection, best production
            execution with on-time shipment, best quality assurance, supply
            chain solutions towards building a stakeholding approach to
            business.
          </p>
          <p>
            We have a dynamic, talented, and well-experienced team working with
            us and a great office structure, including fabric sourcing, garment
            technology & compliance. We have a range of product strengths that
            we offer for Womenswear, Menswear, and Children wear:
            <ul>
              <li>Woven – tops, bottoms, dresses & tailoring</li>
              <li>Outerwear – jackets and coats (except original down)</li>
              <li>Jersey – tops, bottoms & dresses</li>
              <li>Knitwear – sweaters that range from 3gg to 12gg</li>
            </ul>
          </p>
          Our Design and Product Development is the founding principle the
          SA Trademark Business has been built on. We offer high-quality fabrics /yarn
          at very competitive prices inexperienced factories. We have no MOQs
          and therefore can offer a range of colour ways or prints for a style
          with no upcharges.
        </div>

        <div class="row lastBigText">
          <div class="col-1">
            <img class="contentSideLine" src={lineImage}></img>
          </div>
          <div class="col-9">
            <h3>
              We have a dynamic, talented, and well-experienced team working
              with us and a great office structure, including fabric sourcing,
              garment technology & compliance.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
