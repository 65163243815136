import React from "react";
import { Link } from "react-router-dom";
import "./AboutUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { useState, useEffect, useRef } from "react";
import { render } from "@testing-library/react";

export default function AboutUs() {
  return (
    <>
      <div class="about_section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-11 m-auto col-md-12">
              <div class="top-about-us">
                <div class="row">
                  <div class="col-lg-5">
                    <div
                      class="top-about-us-content aos-init aos-animate"
                      data-aos="fade-up"
                      aos-duration="700"
                    >
                      <h1 class="common-title">About Us</h1>
                      <p class="common_p">
                        {" "}
                        SA Trademark is a global hub for
                        multinational apparel sourcing and exporting, our vision
                        is to provide exceptional services, high-quality
                        solutions, and value-added services to the textile
                        industry worldwide. Our expertise spans from fabric
                        sourcing to delivering fashion-forward products, backed
                        by our extensive market knowledge and insights. ...{" "}
                      </p>
                      <a
                        href="ExploreUs"
                        class="common_button"
                      >
                        Read more
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="top-about-us-image aos-init aos-animate"
                      data-aos="fade-up"
                      aos-duration="700"
                    >
                      <img
                        decoding="async"
                        src="https://dongyi.hk/wp-content/uploads/2022/11/aboutus_636494f5613e43.486537401667536117.png"
                        alt="about us"
                        class="img-fluid"
                      />
                      <div class="top-about-us-image-content">
                        <p class="about_top_p">Begin your</p>
                        <h1 class="about_middle_h1">Journey</h1>
                        <p class="about_bottom_p">In our company</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-about-us">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="bottom-about-us-image float-end aos-init"
                      data-aos="fade-up"
                      data-aos-duration="700"
                    >
                      <img
                        decoding="async"
                        src="https://dongyi.hk/wp-content/uploads/2022/11/aboutus_6360c839809904.334448081667287097.jpg"
                        alt="about Us"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      class="about_us_carousel aos-init"
                      data-aos="fade-up"
                      data-aos-duration="700"
                    >
                      <div
                        id="about_us_carousel_id"
                        class="owl-carousel owl-loaded owl-drag"
                      >
                        <div class="owl-stage-outer owl-height">
                          <div class="owl-stage">
                            <div class="owl-item">
                              <div class="item">
                                <div class="about_us_quotetion">
                                  <i className="fa-solid faQuoteLeft">
                                    {" "}
                                    <FontAwesomeIcon icon={faQuoteLeft} />
                                  </i>
                                  <i className={"fa-solid faQuoteRight"}>
                                    <FontAwesomeIcon icon={faQuoteRight} />
                                  </i>
                                </div>
                                <p>Your trusted sourcing partner of choice.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="owl-nav disabled">
                          <button
                            type="button"
                            role="presentation"
                            class="owl-prev"
                          >
                            <span aria-label="Previous">‹</span>
                          </button>
                          <button
                            type="button"
                            role="presentation"
                            class="owl-next"
                          >
                            <span aria-label="Next">›</span>
                          </button>
                        </div>
                        <div class="owl-dots disabled"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
