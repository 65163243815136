import React from "react";
import './Explore_us.css';
import Banner from '../pages/exploreUs/banner/Banner';
import Content from '../pages/exploreUs/content/Content';
import ImagePart from '../pages/exploreUs/imagePart/ImagePart';

export default function Explore_us() {
  return (
    <div className="exploreUs">
      <Banner/>
      <Content/>
      <ImagePart/>
    </div>
  );
}
