import React from "react";
import "./Banner.css";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import contImg1 from "../../../images/about_us.jpg";


export default function ExploreUsBanner() {
  return (
    <div class="exploreUsBanner">
        <img class="photFromExploreUs" src={contImg1}></img>
        <div class="content">
          <h4>ABOUT US</h4>
          <p class="exploreUsLogo">
          <FaFacebook /> &emsp; <FaLinkedin /> &emsp; <FaTwitter /> &emsp;
          <FaInstagram /> &emsp;
          </p>
        </div>
    </div>
  );
}
