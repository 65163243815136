import React from 'react';
import './Intro.css';
import bannerPicOfProducts from '../../../images/catagorylist.jpg';

export default function IntroProducts() {
  return (
    <div class="IntroProducts">
        <img class="photFromIntroProducts" src={bannerPicOfProducts}></img>
        <div class="content">
        <section class="product-page-header">
        <div class="container-fluid h-100">
          <div class="row h-100">
            <div class="col-lg-11 m-auto h-100">
              <div class="product-page-title">
                <h1 data-aos="fade-up" aos-duration="700" class="aos-init aos-animate">PRODUCT LIST</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
        </div>
    </div>
  )
}
