import React from "react";
import "./Management.css";
import person1 from "../../../images/management/DP Aftab.jpg";
import person2 from "../../../images/management/DP Arman Sharif.jpg";

export default function Management() {
  return (
    <div>
      <section class="management-part" id="management">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-11 m-auto col-md-11 col-sm-12 col-12">
              <div class="management-part-inner">
                <h1
                  class="common-title aos-init"
                  data-aos="fade-up"
                  aos-duration="700"
                >
                  Management
                </h1>
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="management-left-content aos-init"
                      data-aos="fade-up"
                      aos-duration="700"
                    >
                      
                      <h3
                        data-aos="fade-up"
                        aos-duration="700"
                        class="aos-init"
                      >
                        Arman Sharif
                      </h3>
                      <h4
                        class="md-designation aos-init"
                        data-aos="fade-up"
                        aos-duration="700"
                      >
                        Founder
                      </h4>
                      <h4
                        class="md-designation aos-init"
                        data-aos="fade-up"
                        aos-duration="700"
                      >
                        SA TradeMark
                      </h4>
                      <p
                        data-aos="fade-up"
                        aos-duration="700"
                        class="aos-init"
                      ></p>
                      <p>
                        At the core of our organizational culture lies a strong
                        commitment to ensuring the satisfaction of both our
                        customers and business partners. It is this unwavering
                        dedication that propels us forward in the fiercely
                        competitive fashion clothing industry. We extend our
                        sincere appreciation to our esteemed clients and
                        business partners for their continuous support, which
                        plays a vital role in nurturing our long-term
                        relationships and paving the way for prosperous future
                        collaborations.
                      </p>

                      <p></p>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
