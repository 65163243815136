import React from "react";
import { Link } from "react-router-dom";
import "./IntroHome.css";
import "bootstrap/dist/css/bootstrap.min.css"; 
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ContactUs from '../../contactUs/Contacts';
import teamPlayer from "../../../images/teamPlayers.jpg";
import dress from "../../../images/fabricAndTerms.jpg";
import CountUp from 'react-countup';

export default function IntroHome() {
  
  return (
    <div className="introHome">
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide header-banner-right aos-init aos-animate"
        data-bs-ride="carousel"
        data-aos="fade-up"
        aos-duration="500"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-lg-6">
              <div class="top_banner_left_content aos-init aos-animate" data-aos="fade-up" aos-duration="700">
                  <h1 class="company_name"> <strong>SA TRADEMARK</strong> <br/></h1>
                  <p> Design | Sourcing | Manufacturing</p>
                    <a href="contactUs">
                    <button class="btn btn-dark">Contact Us</button>
                    </a>
                    <br/>
              </div>
              </div>
              <div class="col-lg-6">
                <div class="top_banner_right">
                  <img src={teamPlayer} class="img-fluid img_1" alt="Slider Image" />
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-lg-6">
              <div class="top_banner_left_content aos-init aos-animate" data-aos="fade-up" aos-duration="700">
                  <h1 class="company_name"> <strong>SA TRADEMARK</strong> <br/></h1>
                  <p> Design | Sourcing | Manufacturing</p>
                  <a href="ContactUs">
                  <button class="btn btn-dark">Contact Us</button>
                  </a>
              </div>
              </div>
              <div class="col-lg-6">
                <div class="top_banner_right">
                  <img src={dress} class="img-fluid img_1" alt="Slider Image" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      

      <section id="stats">
        <div id="stats-cover" class="bg-parallux">
          <div class="content-box">
            <div class="container-fluid">
              <div class="row text-center ">
                <div class="col-lg-9 col-md-12 col-sm-12 col-12">
                  <div class="row counter_content aos-init aos-animate" data-aos="fade-up" aos-duration="700">
                    <div class="col-md-3 col-sm-6 col-6">
                      <div class="stats-item">
                        <h4>
                        <CountUp end={9} duration={10} />    
                        <span>+</span> 
                        </h4> 
                        <p class="">Years In Business</p>
                
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6"> 
                    <h4> 
                      <CountUp end={6} duration={10} />    
                      <span>+</span>  
                    </h4>
                      <p class="">Countries Worldwide</p> 
                    
                    </div>  
                    <div class="col-md-3 col-sm-6 col-6">
                    
                      <div class="stats-item">
                        <h4>
                        <CountUp end={135} duration={10} />  
                      
                        <span>+</span>
                        </h4>
                        <p class="">Staff Worldwide</p>
                      </div>
                      
                      
                    </div>   
                    <div class="col-md-3 col-sm-6 col-6">
                      <div class="stats-item">
                        <h4>
                        <CountUp end={50} duration={10} />  
                    
                        <span>+</span>
                        </h4>
                        <p class="">Recommended</p> 
                      </div>
                      
                    </div>                             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}
