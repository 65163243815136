import React from "react";
import emailjs from '@emailjs/browser';
import { useState, useEffect, useRef } from "react";
import "./Contacts.css";
//import contImg1 from '../images/contact_us.jpg';
import contImg1 from "../../images/Get_in_touch_image.png";


export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1jm05la', 'template_1d1srlj', form.current, 'jN9k23yE1Yp_q87Gx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div class="contact">
      <div class="row">
        <div class="col-lg-6 contRow1Col1">
          <img class="photFromContct" src={contImg1}></img>
        </div>

        <div class="col-lg-6 get_in_touch_col">
          <h1>GET IN TOUCH</h1>
          <p>
            Have an inquiry or some feedback for us? Fill out the form below to
            contact our team.
          </p>
          <form ref={form} onSubmit={sendEmail}>
          <label>Fullname:</label>
          <br />
          <input type='text' name="form_name" class="contact_gap" />
          <br />
          <label>Email:</label>
          <br />
          <input type='email' name="form_email" class="contact_gap" />
          <br />
          <label>Message:</label>
          <br />
          <input type='text-field' name="form_message" class="contact_gap text_field_gap"/>
          <br />
          <button type="submit button" class="btn btn-secondary">  Get in touch</button>

          </form>
        </div>
      </div>
    </div>
  );
}
