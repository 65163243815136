import React from "react";
import "./Services.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

export default function Services() {
  return (
    <div>
      <div class="services_section" id="services">
        <div class="container-fluid p_r_0">
          <div class="row m_r_0">
            <div class="col-lg-12 p_r_0">
              <div class="services_left_part">
                <div class="row m_r_0">
                  <div class="col-lg-6 ">
                    <div class="row justify-content-end">
                      <div class="col-lg-11">
                        <div class="services_left_part_content">
                          <h1
                            class="common-title aos-init"
                            data-aos="fade-up"
                            aos-duration="700"
                          >
                            Services
                          </h1>
                          <p
                            class="title_content aos-init"
                            data-aos="fade-up"
                            aos-duration="700"
                          >
                            These key factors enable us to work with fashion
                            companies around the world:
                          </p>
                          <ul
                            class="services_left_part_content_list list-group aos-init"
                            data-aos="fade-up"
                            aos-duration="700"
                          >
                            <li>
                              {" "}
                              <p class="list_number">1</p>
                              <p class="list_content common_p">
                                Dynamic business models positioned to operate on
                                short lead times, low margins and flexible
                                volume.
                              </p>{" "}
                            </li>
                            <li>
                              {" "}
                              <p class="list_number">2</p>
                              <p class="list_content common_p">
                                Focus on sustainable product offers.
                              </p>{" "}
                            </li>
                            <li>
                              {" "}
                              <p class="list_number">3</p>
                              <p class="list_content common_p">
                                Existing sourcing and production presence in key
                                low-cost and duty-free countries of the world.
                              </p>{" "}
                            </li>
                            <li>
                              {" "}
                              <p class="list_number">4</p>
                              <p class="list_content common_p">
                                Highly competitive product prices and service
                                fees.
                              </p>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 p_r_0">
                    <div
                      class="services_right_part_image aos-init"
                      data-aos="fade-up"
                      aos-duration="700"
                    >
                      <img
                        decoding="async"
                        src="https://dongyi.hk/wp-content/uploads/2022/11/service_main_image.jpg"
                        alt="Service Image"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-11 m-auto">
                        <div class="services_tab_option">
                            <div class="row">
                            <div class="col-lg-3">
                                <div class="list-group aos-init" id="list-tab" role="tablist" data-aos="fade-up" aos-duration="700">
                                                                    <a class="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home-1" role="tab" aria-controls="list-home"> <span><i class="fa-solid fa-arrow-trend-up"><FontAwesomeIcon icon={faArrowTrendUp} /></i></span>  QUALITY</a>
                                                                                                        <a class="list-group-item list-group-item-action " id="list-home-list" data-bs-toggle="list" href="#list-home-2" role="tab" aria-controls="list-home"> <span><i class="fa-solid fa-arrow-trend-up"><FontAwesomeIcon icon={faArrowTrendUp} /></i></span>  INNOVATION</a>
                                                                                                        <a class="list-group-item list-group-item-action " id="list-home-list" data-bs-toggle="list" href="#list-home-3" role="tab" aria-controls="list-home"> <span><i class="fa-solid fa-arrow-trend-up"><FontAwesomeIcon icon={faArrowTrendUp} /></i></span>  COMPLIANCE</a>
                                                                                                    
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="tab-content aos-init" id="nav-tabContent" data-aos="fade-up" aos-duration="700">

                                                                    <div class="tab-pane fade show active" id="list-home-1" role="tabpanel" aria-labelledby="list-home-list">
                                    <div class="row justify-content-between">
                                        <div class="col-lg-5 col-md-5 col-sm-9 col-9">
                                        <div class="services_tab_image">
                                            <img decoding="async" src="https://dongyi.hk/wp-content/uploads/2022/11/service_6360b73e423248.960976741667282750.jpg" alt="Company Attribute" class="img-fluid"/>
                                        </div>
                                        </div>
                                        <div class="col-lg-6">
                                        <div class="services_tab_content">
                                            <p class="common_p"></p><p>From the first sample, we take care of the quality at every step, we identify the key points of each product to anticipate possible difficulties in production.</p><p>We use the Lectra system to create and print the patterns which we then pass on to the manufacturers. All of these actions are meant to decrease the margin of error. Our in-house lab allows us to run the most common tests on each and every order.</p><p></p>                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                                                                                        <div class="tab-pane fade show " id="list-home-2" role="tabpanel" aria-labelledby="list-home-list">
                                    <div class="row justify-content-between">
                                        <div class="col-lg-5 col-md-5 col-sm-9 col-9">
                                        <div class="services_tab_image">
                                            <img decoding="async" src="https://dongyi.hk/wp-content/uploads/2022/11/service_6360beac226324.746112181667284652.png" alt="Company Attribute" class="img-fluid"/>
                                        </div>
                                        </div>
                                        <div class="col-lg-6">
                                        <div class="services_tab_content">
                                            <p class="common_p"></p><p>We are constantly looking for new fabrics, accessories, washes, print and embroidery techniques. To do so, we use our different production offices as a multi-sourcing network to offer the greatest variety to our customers. We are also constantly trying to improve the production processes in our own factories as well as our partner’s. We also made the decision to locate our Design and Graphic Departments in Dhaka (Bangladesh) to be as close as possible to the production facilities, encouraging and pushing them to perform in innovation and development to meet the ever-changing needs of the customer. Our expertise in Denim is highly appreciated by our customers.</p><p></p>                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                                                                                        <div class="tab-pane fade show " id="list-home-3" role="tabpanel" aria-labelledby="list-home-list">
                                    <div class="row justify-content-between">
                                        <div class="col-lg-5 col-md-5 col-sm-9 col-9">
                                        <div class="services_tab_image">
                                            <img decoding="async" src="https://dongyi.hk/wp-content/uploads/2022/11/service_6360b732827b45.131012071667282738.jpg" alt="Company Attribute" class="img-fluid"/>
                                        </div>
                                        </div>
                                        <div class="col-lg-6">
                                        <div class="services_tab_content">
                                            <p class="common_p"></p><p>Our production network is based on compliance, we guarantee our customers that their goods are produced in factories where the workers’ rights are respected.</p><p>We have our own Compliance Department that constantly evaluates the factories and provides them with mandatory follow-up improvements. We constantly strive to improve the working conditions, safety and benefits of the workers. This is made possible by a long-term partnership with our clients and manufacturers.</p><p>We are following international standards and code of conduct such as BSCI, Accord, Wrap, IC</p><p></p>                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                                                                                    </div>

                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
        </div>
      </div>
    </div>
  );
}
