import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/Logo_without_background.png";
import "./Header.css";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const closeNavOnOutsideClick = (event) => {
      if (!event.target.closest(".navbar")) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener("click", closeNavOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeNavOnOutsideClick);
    };
  }, []);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand abs" href="/">
            <img src={logo} alt="SA Trademark Logo" width="130" height="60" />
          </a>
          <button
            className={`navbar-toggler ${isNavOpen ? "collapsed" : ""}`}
            type="button"
            onClick={handleNavToggle}
            aria-controls="navbarNav"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse nav-left ${
              isNavOpen ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item nav-li">
                <Link to="/" className="nav-link" aria-current="page">
                  Home
                </Link>
              </li>
              <li className="nav-item nav-li">
                <Link to="/exploreUs" className="nav-link">
                  Explore Us
                </Link>
              </li>
              <li className="nav-item nav-li">
                <Link to="/products" className="nav-link">
                  Products
                </Link>
              </li>
              <li className="nav-item nav-li">
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
              </li>
              <li className="nav-item nav-li">
                <Link to="/contactUs" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
