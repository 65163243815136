import React from 'react';
import './Blogs.css';

export default function Blogs() {
  return (
    <div className='blogs'>
    
    </div>
  )
}
