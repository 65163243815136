import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Product.css";
import { Dropdown, DropdownButton } from "react-bootstrap";

function ProductCard({ product, baseUrl }) {
  const imageUrl = baseUrl + product.Image;

  return (
    // <div className="card">
    <div class="col-lg-3 col-md-3 col-12 col-sm-6">
      <div class="product-item aos-init" data-aos="fade-up" aos-duration="700">
        <img src={imageUrl} alt={product.Name} className="product-item-img" />
      </div>
    </div>

    //  <h2>{product.Name}</h2>
    // <p>Price: ${product.Price}</p>
    // <p>Section: {product.Section}</p>
    // <p>Exclusive: {product.Exclusive}</p>
    // <p>Available: {product.Available}</p>
    // <p>Created At: {product.created_at}</p>
    // <p>Updated At: {product.updated_at}</p>
  );
}

export default function Product() {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("kid");
  const [apiEndpoint, setApiEndpoint] = useState(
    "http://127.0.0.1:8000/api/allProducts"
  );

  useEffect(() => {
    fetch(apiEndpoint)
      .then((response) => response.json())
      .then((data) => setProducts(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [apiEndpoint]);

  const productsPerRow = 4;

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    var baseUrl = "http://127.0.0.1:8000/api/";
    var url;

    if (category.length > 0) {
      url = baseUrl + "products/" + category;
    } else {
      url = baseUrl + "allProducts";
    }

    setApiEndpoint(url);
  };

  return (
    <section class="product-main-part ">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-11 m-auto">
            <div class="product-page-main-part">
              <div class="row">
                <div class="col-lg-3">
                  <div class="product-left-sidebar">
                    <nav class="navbar2 navbar-light vesco-top-nav">
                      <div
                        class="product-left-sidebar-dropdown"
                        id="navbarNavDropdown"
                      >
                        <ul
                          class="navbar-nav aos-init aos-animate"
                          data-aos="fade-up"
                          aos-duration="700"
                        >
                          <li className="nav-item">
                            <DropdownButton
                              title="KID'S FASHION"
                              id="dropdown-menu"
                              variant="secondary"
                            >
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid denim")
                                }
                              >
                                KID'S BOTTOM & DENIM
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid jersey")
                                }
                              >
                                KID'S JERSEY
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid knitwear")
                                }
                              >
                                KID'S KNITWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid nightwear")
                                }
                              >
                                KID'S NIGHTWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid outerwear")
                                }
                              >
                                KID'S OUTERWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid swim shorts")
                                }
                              >
                                KID'S SWIM SHORTS
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("kid woven dress")
                                }
                              >
                                WOVEN DRESS
                              </Dropdown.Item>
                            </DropdownButton>
                          </li>
                          <li className="nav-item">
                            <DropdownButton
                              title="MEN’S FASHION"
                              id="dropdown-menu"
                              variant="secondary"
                            >
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men jackets & shorts")
                                }
                              >
                                MEN'S JACKETS & SHORTS
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men shirt")
                                }
                              >
                                MEN'S SHIRT
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men sweater")
                                }
                              >
                                MEN'S SWEATER
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men denim")
                                }
                              >
                                MEN’S DENIM
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men jersey")
                                }
                              >
                                MEN’S JERSEY
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men knitwear")
                                }
                              >
                                MEN’S KNITWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men outerwear")
                                }
                              >
                                MEN’S OUTERWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("men tailoring")
                                }
                              >
                                MENS TAILORING
                              </Dropdown.Item>
                            </DropdownButton>
                          </li>

                          <li className="nav-item">
                            <DropdownButton
                              title="WOMEN’S FASHION"
                              id="womenFashionDropdown"
                              variant="secondary"
                            >
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women blouse")
                                }
                              >
                                WOMEN'S BLOUSE
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women sweater")
                                }
                              >
                                WOMEN'S SWEATER
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women tailoring")
                                }
                              >
                                WOMEN'S TAILORING
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women denim")
                                }
                              >
                                WOMEN’S DENIM
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women knitwear")
                                }
                              >
                                WOMEN’S KNITWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women nightwear")
                                }
                              >
                                WOMEN’S NIGHTWEAR
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women tops & dresses")
                                }
                              >
                                WOMEN’S TOPS & DRESSES
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleCategoryChange("women woven bottoms")
                                }
                              >
                                WOMEN’S WOVEN BOTTOMS
                              </Dropdown.Item>
                            </DropdownButton>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-12">
                      <div
                        class="products-navtab-menu aos-init aos-animate"
                        data-aos="fade-up"
                        aos-duration="700"
                      >
                        <ul
                          class="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link active"
                              onClick={() => handleCategoryChange("kid")}
                            >
                              KID'S FASHION
                            </a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link active"
                              onClick={() => handleCategoryChange("men")}
                            >
                              MEN’S FASHION
                            </a>
                          </li>
                          <li class="nav-item" role="presentation">
                            <a
                              class="nav-link active"
                              onClick={() => handleCategoryChange("women")}
                            >
                              WOMEN’S FASHION
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="category-1"
                          role="tabpanel"
                          aria-labelledby="category-1-tab"
                        >
                          <div class="row">
                            {products.map((product, index) => (
                              <React.Fragment key={product.id}>
                                <ProductCard
                                  product={product}
                                  baseUrl="http://127.0.0.1:8000/storage/"
                                />
                                {(index + 1) % productsPerRow === 0 && <br />}{" "}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-container"> */}

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
