import React from 'react';
import './Product.css';
import Banner from '../pages/productList/intro/Intro.js';
import Body from '../pages/productList/product/Product.js';

export default function Products() {
  return (
    <div className='Product'>
      <Banner/>
      <Body />
    </div>
  )
}
