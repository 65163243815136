import React from 'react';
import './Products.css';
import Banner from '../pages/products/intro/Intro.js';
import Body from '../pages/products/catagory/Catagory.js';

export default function Products() {
  return (
    <div className='Products'>
      <Banner/>
      <Body />
    </div>
  )
}
