import React from "react";
import ethicsLeftImage from "../../../images/teamPlayers.jpg";
import damiLogo from "../../../images/demoLogo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Ethics.css";

export default function Ethics() {
  return (
    <div>
      <div className="vision-part" id="ethics">
        <div className="container-fluid p_l_0">
          <div className="row m_l_0">
            <div className="col-lg-12 p_r_0 col-md-12 col-sm-12 col-12">
              <div className="visions_left_part">
                <div className="row m_l_0">
                  <div className="col-lg-7 p_l_0 visions_left_part_padding_right">
                  <div class="vision_left_part_image">
                      <img src={ethicsLeftImage} alt="" class="img-fluid" />
                      <div class="vision_left_part_content">
                        <div class="vision-carosule">
                          <div class="row">
                            <div class="col-lg-11 m-auto col-xl-11 col-xxl-11 col-sm-12 col-md-12 col-12">
                              <div
                                class="vision-carousle-title aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-duration="700"
                              >
                                <h2>
                                  OUR MANUFACTURING PARTNERS ARE COMPLIANT WITH
                                  INTERNATIONAL STANDARDS AND CODE OF CONDUCT:
                                </h2>
                              </div>
                            </div>
                            <div class="col-lg-11 col-xl-11 col-xxl-11 col-sm-12 col-md-12 col-12">
                              <div
                                id="vision-list"
                                class="vision-list-class aos-init slick-initialized slick-slider"
                                data-aos="fade-up"
                                data-aos-duration="700"
                              >
                                <div
                                  aria-live="polite"
                                  class="slick-list draggable"
                                >
                                  <div
                                    class="slick-track"
                                    style={{
                                      opacity: 1,
                                      width: "324px",
                                      transform: "translate3d(0px, 0px, 0px)",
                                    }}
                                    role="listbox"
                                  >
                                    <div
                                      class="slick-slide slick-current slick-active"
                                      data-slick-index="0"
                                      aria-hidden="false"
                                      style={{ width: "142.562px" }}
                                      tabIndex="-1"
                                      role="option"
                                      aria-describedby="slick-slide00"
                                    >
                                      <div>
                                        <div
                                          class="vision-item"
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          class="vision-item"
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            decoding="async"
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          class="vision-item"
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            decoding="async"
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="slick-slide slick-active"
                                      data-slick-index="1"
                                      aria-hidden="false"
                                      style={{ width: "142.562px" }}
                                      tabIndex="-1"
                                      role="option"
                                      aria-describedby="slick-slide02"
                                    >
                                      <div>
                                        <div
                                          class="vision-item "
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            decoding="async"
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          class="vision-item"
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            decoding="async"
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          class="vision-item"
                                          style={{
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <img
                                            decoding="async"
                                            src={damiLogo}
                                            alt="Manufacturing"
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                ;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row justify-content-start">
                      <div className="col-lg-11 col-xl-11 col-xxl-11 col-sm-12 col-md-12 col-12">
                        <div className="vision_right_part_content">
                          <h1
                            className="common-title-right aos-init"
                            data-aos="fade-up"
                            data-aos-duration="700"
                          >
                            Ethics
                          </h1>
                          <div
                            className="our-vision-content aos-init"
                            data-aos="fade-up"
                            data-aos-duration="700"
                          >
                            <h2>OUR VISION</h2>
                            <p className="our-vision-content-p">
                              To become a reliable global apparel sourcing
                              organization that provides excellence and quality
                              through innovation with a forward-looking
                              approach. We will achieve this position through
                              innovations in products and business methods that
                              add value to our customers and by meeting global
                              standards in quality, customer service.&nbsp;
                            </p>
                            <div className="our-vision-content-image">
                              <div className="image-body-vision">
                                <img
                                  decoding="async"
                                  src="https://dongyi.hk/wp-content/uploads/2022/11/ethics_6360bfc1ee5508.123496181667284929.png"
                                  alt="Ethics"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
