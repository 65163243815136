import React from "react";
import "./DesignStudio.css";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

export default function DesignStudio() {
  return (
    <div>
      <div class="design_studio_part" id="design_studio">
        <div class="container-fluid p_l_0">
          <div class="row m_l_0">
            <div class="col-lg-12 p_r_0">
              <div class="design_studio_left_part">
                <div class="row m_l_0">
                  <div class="col-lg-6 p_l_0"></div>
                  <div
                    class="design_studio_left_part_image aos-init aos-animate"
                    data-aos="fade-up"
                    aos-duration="700"
                  >
                    <div
                      id="design_studio_left_carousel_id"
                      class="owl-carousel owl-loaded owl-drag"
                    >
                        <div className="design_studio_part" id="design_studio">
      <div className="container-fluid p_l_0">
        <div className="row m_l_0">
          <div className="col-lg-12 p_r_0">
            <div className="design_studio_left_part">
              <div className="row m_l_0">
                <div className="col-lg-6 p_l_0">
                  <div className="design_studio_left_part_image">
                    <div className="owl-stage-outer owl-height">
                      <div className="owl-stage">
                        <div className="owl-item cloned">
                          <div className="item">
                            <img
                              src="https://dongyi.hk/wp-content/uploads/2022/11/studio_6360c30930f3f9.939243201667285769.jpg"
                              alt="Design studio"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row justify-content-start">
                    <div className="col-lg-11">
                      <div className="design_studio_right_part_content">
                        <h1 className="common-title-right">Design Studio</h1>
                        <div className="design-studio-carosule-main">
                          <div className="owl-carousel owl-loaded owl-drag">
                            <div className="owl-stage-outer owl-height">
                              <div className="owl-stage">
                                <div className="owl-item cloned">
                                  <div className="item">
                                    <div className="about_us_quotetion">
                                     <i className='fa-solid faQuoteLeft'> <FontAwesomeIcon icon={faQuoteLeft} /></i>
                                      <i className={'fa-solid faQuoteRight'}><FontAwesomeIcon icon={faQuoteRight} /></i>
                                    </div>
                                    <p>Professional &amp; Strong in-house design studio to deliver design solutions and creatively translate your vision</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
