import React from "react";
import "./ImagePart.css";
import row1col1 from "../../../images/ourMission.jpg";
import row1col2 from "../../../images/fabricAndTerms.jpg";
import row1col3 from "../../../images/shapeDesign.jpg";
import row2col1 from "../../../images/trandAndForcast.jpg";
import row2col2 from "../../../images/ourVision.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  // Global settings:
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  once: false, // whether animation should happen only once - while scrolling down
  mirror: true, // whether elements should animate out while scrolling past them
});

export default function ImagePart() {
  // ScrollReveal().reveal('.headline');
  return (
    <div className="imagePart">
      <div class="row">
        <div
          class="col-lg-4 imageSection"
          data-aos="fade-up"
          data-aos-anchor="#example-anchor"
          data-aos-offset="1200"
          data-aos-duration="2000"
        >
          <img class="imageFromExlporeUsImagePart" src={row1col1}></img>
          <div class="content">
            <h3>OUR MISSION</h3>
            Setting up an ethical business standard by providing best service,
            quality and commitment. Implementing eco-friendly technologies to
            all associated factories through trained and knowledgeable workforce
            to offer highest level of customer satisfaction. Ensuring
            sustainable growth through innovation, talents and operational
            efficiencies.
          </div>
        </div>
        <div
          class="col-lg-4 imageSection"
          data-aos="fade-up"
          data-aos-anchor="#example-anchor"
          data-aos-offset="1500"
          data-aos-duration="2500"
        >
          <img class="imageFromExlporeUsImagePart" src={row1col2}></img>
          <div class="content">
            <h3>FABRIC & TRIM R&D</h3>
            Our fabric sourcing, development & research team works continuously to deliver innovative fabrics and trims for achieving speed to market and fashion in the high street.
          </div>
        </div>
        <div
          class="col-lg-4 imageSection"
          data-aos="fade-up"
          data-aos-anchor="#example-anchor"
          data-aos-offset="1800"
          data-aos-duration="2500"
        >
          <img class="imageFromExlporeUsImagePart" src={row1col3}></img>
          <div class="content">
            <h3>SHAPE DESIGN</h3>
            With a track record for innovation, we build our strength and capabilities to provide the exceptional standard of design for our customers, helping them to achieve their vision.
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 imageSection"
          data-aos="fade-up"
          data-aos-anchor="#example-anchor"
          data-aos-offset="1900"
          data-aos-duration="2500"
        >
          <img class="imageFromExlporeUsImagePart" src={row2col1}></img>
          <div class="content">
            <h3>TREND & FORECAST
TREND & FORECAST</h3>
Our Development & research team achieves market intelligence with regular global trips, complemented. A platform that helps us provide our clients with confident insights to help make the right decisions
          </div>
        </div>
        <div
          class="col-lg-8 imageSection"
          data-aos="fade-up"
          data-aos-anchor="#example-anchor"
          data-aos-offset="1900"
          data-aos-duration="2500"
        >
          <img class="imageFromExlporeUsImagePartRow2" src={row2col2}></img>
          <div class="content">
            <h3>OUR VISION</h3>
            To become a reliable global apparel sourcing organization that provides excellence and quality through innovation with a forward-looking approach. We will achieve this position through innovations in products and business methods that add value to our customers and by meeting global standards in quality, customer service.
          </div>
        </div>
      </div>
    </div>
  );
}
