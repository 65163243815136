import React from 'react';
import './Home.css';
import IntroHome from '../pages/home/intro/IntroHome.js';
import AboutUs from './home/aboutUs/AboutUs';
import DesignStudio from './home/designStudio/DesignStudio';
import Services from './home/services/Services';
import Ethics from './home/ethics/Ethics';
import HomeProducts from './home/products/HomeProducts';
import Management from './home/management/Management';

export default function Home() {
  return (
    <div className='home'>
      <IntroHome/>
      <AboutUs/>
      <DesignStudio/>
      <Services/>
      <Ethics/>
      <HomeProducts/>
      <Management/>
    </div>
  )
}
