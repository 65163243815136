import React from "react";
import MensDenim from "../../../images/mensDenim.png";
import MensJersy from "../../../images/mensJersy.png";
import MensKnitwear from "../../../images/mensKnitwear.png";
import MensOuterwear from "../../../images/mensOuterwear.png";
import MensSweater from "../../../images/mensSweater.jpg";
import WomensDenim from "../../../images/womensDenim.png";
import WomensKnitwear from "../../../images/womensKnitwear.png";
import WomensSweater from "../../../images/womensSweater.png";
import WomensTopsDresses from "../../../images/womensTops&Dresses.png";
import WomensWovenBottoms from "../../../images/womensWovenBottoms.png";
import "./Catagory.css";
import { Link } from "react-router-dom";

export default function ProductCatagory() {
  return (
    <div className="productCatagory">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-11 m-auto">
            <div class="product-page-main-part">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="category-1"
                          role="tabpanel"
                          aria-labelledby="category-1-tab"
                        >
                          <div class="row">
                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init aos-animate"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={MensDenim}
                                    alt="MEN’S DENIM"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                <div class="product-item-title">
                                  <h3>MEN’S DENIM</h3>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init aos-animate"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={MensJersy}
                                    alt="MEN’S JERSEY"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                              
                                
                                <div class="product-item-title">
                                  <h3>MEN’S JERSEY</h3>
                                </div>
                              </div>
                              
                              
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={MensKnitwear}
                                    alt="MEN’S KNITWEAR"
                                    class="img-fluid"
                                  />
                                </div>

                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>MEN’S KNITWEAR</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={MensOuterwear}
                                    alt="MEN’S OUTERWEAR"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>MEN’S OUTERWEAR</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={MensSweater}
                                    alt="MENS SWEATER"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>MENS SWEATER</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={WomensSweater}
                                    alt="WOMEN'S SWEATER"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
        
                                <div class="product-item-title">
                                  <h3>WOMEN'S SWEATER</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={WomensDenim}
                                    alt="WOMEN’S DENIM"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>WOMEN’S DENIM</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={WomensKnitwear}
                                    alt="WOMEN’S KNITWEAR"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>WOMEN’S KNITWEAR</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={WomensTopsDresses}
                                    alt="WOMEN’S TOPS &amp; DRESSES"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                                
                                <div class="product-item-title">
                                  <h3>WOMEN’S TOPS &amp; DRESSES</h3>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-3 col-md-3 col-12 col-sm-4">
                              <div
                                class="product-item aos-init"
                                data-aos="fade-up"
                                aos-duration="700"
                              >
                                <Link to='/product'>
                                <div class="product-item-img">
                                  <img
                                    decoding="async"
                                    src={WomensWovenBottoms}
                                    alt="WOMEN’S WOVEN BOTTOMS"
                                    class="img-fluid"
                                  />
                                </div>
                                </Link>
                               
                                <div class="product-item-title">
                                  <h3>WOMEN’S WOVEN BOTTOMS</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
